import * as i from 'types';
import * as React from 'react';

import { configureImageSrc, getSlug } from 'services';
import { Text } from 'common/typography';
import MailIcon from 'vectors/mail.svg';
import TelephoneIcon from 'vectors/telephone.svg';

import {
  CardContainer,
  ImageHeader,
  CardContent,
  CardHeading,
  AddressColumn,
  ContactInfoColumn,
  ContactInfoRow,
} from './styled';

export const StudioCard: React.FC<StudioCardProps> = ({ item }) => (
  <CardContainer to={getSlug('studio', item.slug) || ''}>
    {item.frontImage?.file?.url && <ImageHeader src={configureImageSrc(item.frontImage.file.url)} alt="" />}
    <CardContent>
      <CardHeading>{item.name}</CardHeading>
      <AddressColumn>
        <Text $lineHeight="18px" variant="gray">{item.streetAddress}</Text>
        <Text $lineHeight="18px" variant="gray">{item.zipCode}</Text>
      </AddressColumn>
      <ContactInfoColumn>
        <ContactInfoRow $flexGap="16px">
          <TelephoneIcon />
          <Text $lineHeight="18px" variant="primary">{item.telephoneNumber}</Text>
        </ContactInfoRow>
        <ContactInfoRow $flexGap="12px">
          <MailIcon />
          <Text $lineHeight="18px" variant="primary">{item.emailaddress}</Text>
        </ContactInfoRow>
      </ContactInfoColumn>
    </CardContent>
  </CardContainer>
);

type StudioCardProps = {
  item: i.Studio;
};
