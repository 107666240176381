import * as i from 'types';
import * as React from 'react';

import { useDevice, useSelector } from 'services/hooks';
import { selectActiveSiteData } from 'selectors';
import { Heading } from 'common/typography';
import { configureImageSrc } from 'services';

import { StudioCard } from '..';
import { ContentContainer, StudiosList, MapContainer, StudiosContentWrapper } from './styled';

export const StudiosContent: React.FC<StudiosContentProps> = ({ studios, cities }) => {
  const activeSite = useSelector(selectActiveSiteData);
  const { isMobile } = useDevice();
  const filteredStudios = studios?.filter((studio) => studio.node.city?.cityTitle === activeSite);
  const selectedCity = cities.filter((city) => city.node.cityTitle === activeSite);

  return (
    <ContentContainer>
      {activeSite && (
        <>
          {!isMobile && (
            <MapContainer src={configureImageSrc(selectedCity[0]?.node?.studioOverviewImage.file.url)} alt={selectedCity[0]?.node?.cityTitle} />
          )}
          <StudiosContentWrapper>
            <Heading upperCase weight={700} variant="black" as="h3">{selectedCity[0].node.cityTitle}</Heading>
            <StudiosList>
              {filteredStudios.map((studio) => (
                <StudioCard item={studio.node} key={studio.id} />
              ))}
            </StudiosList>
          </StudiosContentWrapper>
        </>
      )}
    </ContentContainer>
  );
};

type StudiosContentProps = {
  cities: {
    node: i.City;
  }[];
  studios: {
    id: string;
    node: i.Studio;
  }[];
};
